import { Component } from 'react';
import Markdown from "../../../utilities/Markdown";

export default class AlertComponent extends Component {
    render() {
        const { message } = this.props;

        return <Markdown source={message} withStyles={true} />;
    }
}
